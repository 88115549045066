<template>
  <div>
    <modal :show.sync="migrateModal" :required="true" headerClasses="justify-content-center" modalClasses="modal-lg">
      <template slot="header">
        <h4 class="title title-up">{{ $t('attentiontxt') }}</h4>
      </template>

      <template slot="default">
        <div class="row text-left">
          <div class="col-md-12">
            <label>{{ txt.selectTypeMigration }}</label>
            <select class="form-control"
                    placeholder="Insert Type"
                    v-model="selected"
                    addon-right-icon="fa fa-id-card">
              <option v-for="op in selectOption"
                      :value="op.code"
                      :key="op.code">
                {{ op.name }}
              </option>
            </select>
            <span>&nbsp;</span>
          </div>

          <div class="col-md-12" v-if="selected === 'strat'">
            <label>{{ txt.matServer }}</label>
            <fg-input placeholder="MAT"
                      v-model="oldMatName"
                      addon-right-icon="fa fa-desktop">
            </fg-input>

            <label>{{ txt.strategy }}</label>
            <fg-input v-model="accstrat" addon-right-icon="nc-icon nc-sound-wave" type="text" :placeholder="choosestrat"
                      v-on:keyup="searchStrats"></fg-input>
            <ul v-if="showAutoResultsStrats" class="auto-ul auto-ul-strategies">
              <li v-for="r in autoResultsStrats" v-on:click="pickedStrat(`${r.strat_id}`)">{{ r.strat_name }}</li>
            </ul>
            <span>&nbsp;</span>
          </div>

          <div class="col-md-12" v-if="selected === 'mat'">
            <label>{{ txt.matServer }}</label>
            <fg-input class="input-sm"
                      :placeholder="txt.matPlaceHolder"
                      v-model="oldMatName"
                      addon-right-icon="fa fa-desktop">
            </fg-input>
            <span>&nbsp;</span>
          </div>

          <div class="col-md-12" v-if="selected === 'account'">
            <label>{{ txt.account }}</label>
            <fg-input type="number"
                      class="input-sm"
                      placeholder="Insert Account"
                      v-model="account"
                      addon-right-icon="fa fa-id-card">
            </fg-input>
            <span>&nbsp;</span>
          </div>

          <div class="col-md-12 text-center">
            <h3> {{ txt.totxt }} </h3>
          </div>

          <div class="col-md-12">
            <label>{{ txt.newMat }}</label>
            <fg-input class="input-sm"
                      :placeholder="txt.matPlaceHolder"
                      v-model="matName"
                      addon-right-icon="fa fa-desktop">
            </fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6 text-right">
          </div>
        </div>
      </template>

      <template slot="footer">
        <div class="py-3 mx-auto" v-if="modalinprogress">
          <i class="fa fa-cog fa-spin fa-2x fa-fw" style="color: #51bcda;"></i>
        </div>
        <div class="right-side" v-if="!modalinprogress">
          <p-button type="success" v-on:click="confirmMigrateMat()" link>
            {{ $t('confirm') }}
          </p-button>
        </div>
        <div class="divider" v-if="!modalinprogress"></div>
        <div class="left-side" v-if="!modalinprogress">
          <p-button type="info" v-on:click="closeMigrateModal()" link>
            {{ $t('cancel') }}
          </p-button>
        </div>
      </template>

    </modal>
    <div>
      <p-button type="primary" slot="footer" v-on:click="openMigrateModal()">
        {{ txt.migrateToNewMATButton }}
      </p-button>
    </div>
  </div>
</template>

<script>
import {Modal} from 'src/components/UIComponents';
import swal from "sweetalert2";

export default {
  name: "MigrateMAT",
  components: {
    Modal,
  },
  data() {
    return {
      txt: {
        selectTypeMigration: this.$t('selectTypeMigration'),
        matServer: this.$t('matServer'),
        strategy: this.$t('strategytxt'),
        matPlaceHolder: this.$t('matPlaceHolder'),
        account: this.$t('account'),
        newMat: this.$t('newMat'),
        totxt: this.$t('totxt'),
        migrateToNewMATButton: this.$t('migrateToNewMAT'),
      },
      migrateModal: false,
      inprogress: true,
      account: '',
      matName: '',
      selectOption: [
        {
          name: this.$t('accountToMAT'),
          code: 'account'
        },
        {
          name: this.$t('matToMat'),
          code: 'mat'
        },
        {
          name: this.$t('stratMatToMat'),
          code: 'strat'
        }
      ],
      selected: 'account',
      strategies: [],
      accstrat: '',
      strat_id: '',
      oldMatName: '',
      autoResultsStrats: [],
      showAutoResultsStrats: false,
      choosestrat: this.$t('choosestrat'),
      modalinprogress: false,
    }
  },
  methods: {
    openMigrateModal() {
      this.migrateModal = true;
    },
    closeMigrateModal() {
      this.resetModal()
      this.migrateModal = false;
    },
    resetModal() {
      this.account = '';
      this.matName = '';
      this.selected = 'account';
      this.accstrat = '';
      this.oldMatName = '';
    },
    reloadAndreset(){
      this.resetModal()
      this.$parent.callAccountsLocation();
    },
    confirmMigrateMat() {
      if (this.selected === 'account' && (this.account === "" || this.matName === "")) {
        swal(this.$t('attentiontxt'), this.$t('emptyfields'), "warning")
      } else if (this.selected === 'strat' && (this.strat_id === "" || this.oldMatName === "" || this.matName === "")) {
        swal(this.$t('attentiontxt'), 'emptyfields', "warning")
      } else if (this.selected === 'mat' && (this.oldMatName === "" || this.matName === "")) {
        swal(this.$t('attentiontxt'), this.$t('emptyfields'), "warning")
      } else {
        this.modalinprogress = true;

        const opSuccess = resp => {
          this.modalinprogress = false;
          if (resp.success) {
            swal(this.$t('successtxt'), "Deu bom meu galo!", "success").then(this.reloadAndreset)
          }
          return;
        }

        const opFailed = resp => {
          this.modalinprogress = false;
          swal(this.$t('attentiontxt'), this.$t('rdpdownmsg'), "error")
        }

        if (this.selected === 'account') {
          const param = {
            account: this.account,
            mat: this.matName,
          }
          this.$setAccountInMAT(param).then(opSuccess, opFailed)
        } else if (this.selected === 'mat') {
          const param = {
            oldMat: this.oldMatName,
            newMat: this.matName,
          }
          this.$setMatToNewMAT(param).then(opSuccess, opFailed)
        } else if (this.selected === 'strat') {
          const param = {
            oldMat: this.oldMatName,
            newMat: this.matName,
            stratId: this.strat_id
          }
          this.$realocateStrategyToNewMAT(param).then(opSuccess, opFailed)
        }
      }
    },
    loadStrategies(resp) {
      this.strategies = [];
      for (var i = 0; i < resp.data.length; i++) {
        this.strategies.push(resp.data[i]);
      }
    },
    pickedStrat(strat) {
      const sidx = this.strategies.findIndex(s => s.strat_id == strat);
      this.accstrat = this.strategies[sidx].strat_name;
      this.strat_id = this.strategies[sidx].strat_id;
      this.showAutoResultsStrats = false;
      this.autoResultsStrats = [];
    },
    searchStrats(k) {
      if (this.accstrat !== undefined && this.accstrat !== null && this.accstrat.length > 0) {
        this.autoResultsStrats = [];

        for (var i = 0; i < this.strategies.length; i++) {
          let r = new RegExp(this.accstrat, "gi");
          if (this.strategies[i].strat_name.match(r) !== null) {
            this.autoResultsStrats.push(this.strategies[i]);
          }
        }
        this.showAutoResultsStrats = this.autoResultsStrats.length > 0;
      } else {
        this.showAutoResultsStrats = false;
      }
    },
  },
  mounted() {
    this.inprogress = false
    this.$getStrategiesOverview().then(this.loadStrategies, this.failop);
  }
}
</script>

<style scoped lang="scss">
.auto-ul-strategies {
  height: 85px;
}

.auto-ul {
  list-style: none;
  padding: 5px;
  overflow: scroll;
  height: 100px;
  position: absolute;
  background-color: white;
  z-index: 9999;
  border: 1px solid silver;
  width: inherit;
}

.auto-ul li:hover {
  background: silver;
  cursor: pointer;
}

</style>
